import '@b2c/core/app/service/domain/history.js';
import '@b2c/core/app/service/domain/user.js';

(function(){

	'use strict';

	// this directive used for watching all inputs in the form and send formData to backabd after blur event

	const directive = { name: 'translateAmount' };
	const localStorageKey = 'saveDepAmount'

	controller.$inject = [ "$translate", "$state", "history", "user" ];

	function controller($translate, $state, _history, user){
		function link( scope ) {
			scope.prealoader = true;

			if (!scope.formData) {
				scope.formData = {}
			}
			$translate.use( $state.params.lang );

			scope.filter = {
				type: 'deposit',
				period: 'week',
				datefrom: _history.getFormattedYesterday(),
				dateto: _history.getFormattedToday(),
			};

			const opt = {
				params: scope.filter,
				endpoint: 'payment',
			};

			if (user.status) {
				_history.collection(opt).then(
					(answer) => {
						scope.preloader = false;
						scope.lastDeposit = answer.result[0];

						if(scope.lastDeposit) {
							scope.formData.amount = parseInt(scope.lastDeposit.amount);
						}
					},
					(answer) => {
						scope.preloader = false;
					}
				);
			}


			$translate.onReady(() => {

				setTimeout(() => {

					Promise.all([
						$translate.instant("currency.150"),
						$translate.instant("currency.100"),
						$translate.instant("currency.50"),
					])
						.then((amount) => {
							scope.prealoader = false;

							scope.amountMax = Number(amount[0]);
							scope.amountMidl = Number(amount[1]);
							scope.amountMin = Number(amount[2]);

							const lesAmount = window.localStorage.getItem(localStorageKey);

							scope.formData.amount = lesAmount ? parseInt(lesAmount) : Number(amount[1]);

						})
				}, 1000);
			})

			scope.activeTab = window.localStorage.getItem(localStorageKey) || 'amount';
			scope.selectAmount = (value) => {
				scope.activeTab = value;
				scope.formData.amount = value;

				window.localStorage.setItem(localStorageKey, value)
			}
		}

		return {
			restrict: 'A',
			link,
		};
	}

	app.directive( directive.name, controller );


})();
