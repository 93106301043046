const _temp0 = require("./directive/add-class-registration-page.js");
const _temp1 = require("./directive/alf-checked-wb.js");
const _temp2 = require("./directive/current-slide-to-scope.js");
const _temp3 = require("./directive/save-dep-amount.js");
const _temp4 = require("./directive/translate-amount.js");
module.exports = {
  "add-class-registration-page.js": _temp0,
  "alf-checked-wb.js": _temp1,
  "current-slide-to-scope.js": _temp2,
  "save-dep-amount.js": _temp3,
  "translate-amount.js": _temp4
}