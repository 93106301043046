import '../../service/domain/game.js';

(function () {
  'use strict';

  const component = { name: 'lindaGameFieldMobile' };

  webController.$inject = ['$scope', 'game', '$state'];

  function webController($scope, _game, $state) {
    $scope.preloader = true;
    $scope.url = '';
    $scope.preloader = true;

    _game.launch({ code: $state.params.name, mode: $state.params.mode }).then(
      (answer) => {
        $scope.url = answer.result.url;
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      }
    );
  }

  mobileController.$inject = ['$scope', 'game', '$state', 'appBrowser'];

  function mobileController($scope, _game, $state, _appBrowser) {
    $scope.preloader = true;
    $scope.url = '';

    _game.launch({ code: $state.params.name, mode: $state.params.mode }).then(
      (answer) => {
        _appBrowser
          .onExit(() => {
            history.back();
          })
          .open(answer.result.url);
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller: app.getCC(mobileController, webController),
    template: app.getTU(component.name),
  });
})();
